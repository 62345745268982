import 'styles.less'
import 'helper'
import React from 'react';
import ReactDOM from 'react-dom'
import { lookup } from 'dns';
import { helper } from 'helper';


let data = {
  metal: 0
}


class Square extends React.Component<any, any> {
  state: {
    value: number,
    level: number,
    activity: string
  }
  mining = false
  constructor(props: any) {
    super(props);
    this.state = {
      value: data.metal,
      level: 0,
      activity: 'ready...'
    }
  }

  componentWillMount() {
  }

  componentDidMount() {
    let canvas = document.getElementById('canvas') as HTMLCanvasElement
    let context = canvas.getContext('2d')
    context.globalCompositeOperation = 'source-over'
    context.beginPath()
    context.moveTo(50, 50)
    context.arc(50, 50, 40, 0, 2 * Math.PI, false)
    context.closePath()
    context.fillStyle = 'rgba(100, 100, 100, .8)'
    context.fill()
    context.beginPath()
    context.moveTo(50, 50)
    context.arc(50, 50, 42, 0, 2 * Math.PI, false)
    context.closePath()
    context.strokeStyle = 'rgba(100, 100, 100, .8)'
    context.stroke()
    context.beginPath()
    context.moveTo(50, 50)
    context.arc(50, 50, 40, 0, Math.PI, false)
    context.closePath()
    context.fillStyle = 'rgba(255, 255, 255, .8)'
    context.fill()
    context.globalCompositeOperation = 'destination-out'
    context.beginPath()
    context.moveTo(50, 50)
    context.arc(50, 50, 26, 0, 2 * Math.PI, false)
    context.closePath()
    context.fillStyle = 'white'
    context.fill()
  }

  startMining() {
    if (!this.mining) {
      this.mining = true
      this.setState({ activity: 'mining...' })
      helper.setIntervalX_instant(
        1000 - (100 * this.state.level),
        5,
        () => {
          data.metal++
          this.forceUpdate()
        },
        () => {
          this.mining = false
          this.setState({ activity: 'ready...' })
        }
      )
    }
  }

  levelUp() {
    if (data.metal >= 25) {
      data.metal -= 25
      this.setState({
        level: this.state.level + 1
      })
    }
  }

  render() {
    return (
      <div>
        <canvas className='circle' id='canvas' width='100' height='100'></canvas>
        <div>
          METAL: {data.metal}
        </div>
        <button
          id='0'
          onMouseDown={() => { this.startMining() }}
        >
          DRILL (level {this.state.level}) {this.state.activity}
        </button>
        <br />
        <br />
        <button
          id='1'
          onMouseDown={() => { this.levelUp() }}
        >
          UPGRADE DRILL (25 Metal)
        </button>
      </div>
    );
  }
}

ReactDOM.render(
  <Square />
  , document.getElementById('root')
)

function loop() {
  window.requestAnimationFrame(loop)

}
loop()

