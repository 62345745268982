import { Easing, Tween, autoPlay } from 'es6-tween'

String.prototype.stripAllNonLetters = function(): string {
    return this.replace(/[^A-Za-z0-9\u0600-\u06FF\s\\]/g, '')
}

export namespace helper {

    let uniqueNum = 0
    let uniqueNameNum = 0

    export function getUniqueID(): number {
        return uniqueNum++
    }

    export function getUniqueName(): string {
        return 'u' + uniqueNameNum++
    }

    export function setIntervalX_instant(delay: number, repetitions: number, callback: Function, finalCallback: Function) {
        let x = 1;
        callback()
        let intervalID = window.setInterval(function () {
           callback()
           if (++x === repetitions) {
               window.clearInterval(intervalID)
               finalCallback()
           }
        }, delay)
    }
    
}

function tweeningStuff() {
    let elem = document.getElementById('grid')
    let style = window.getComputedStyle(elem, null)
    elem.style['grid-template-columns'] = "40px auto"
    autoPlay(true)
    let t = new Tween({x: 0})
    t.to({x: 100}, 400)
    t.easing(Easing.Quadratic.InOut)
    t.on('update', ({x}) => {
        elem.style['grid-template-columns'] = `${x}px auto`
    })
    t.start()
}